import React from 'react'
import { FlashMessageContext, FlashMessageDispatch } from 'providers/FlashMessageProvider';
import { setErrorMessageAction } from 'reducers/useFlashMessageReducer';
import * as Sentry from "@sentry/react";
import PrivateAssetDownloadContext, { DownloadPrivateAssetFn } from './context'

export { default as PrivateAssetDownloadContext } from './context'
export type {
  CreateDownloadUrlResponse,
  DownloadPrivateAssetFn
} from './context'

type BuildDownloadPrivateAssetProps = {
  downloadUrls: string[]
  setDownloadUrls: (du: string[]) => void
  flashMessageDispatch: FlashMessageDispatch
}

export const buildDownloadPrivateAsset = (props: BuildDownloadPrivateAssetProps): DownloadPrivateAssetFn => {
  const { downloadUrls, setDownloadUrls, flashMessageDispatch } = props

  return (createDownloadUrl, downloadEvent, mapId, downloadEventProperties): Promise<string> => (
    new Promise((resolve) => {
      createDownloadUrl()
        .then(({ url, follower, vttDownloadTarget }) => {
          setDownloadUrls(downloadUrls.concat(url))
          const eventOpts = {
            mixpanelProperties: {
              ...(downloadEventProperties || {}),
              ...{ map_id: mapId, vtt_download_target: vttDownloadTarget }
            }
          }
          eventOpts.mixpanelProperties.follower = follower
          window.tracker.track(downloadEvent, eventOpts)
          resolve(url)
        })
        .catch((error) => {
          const message = "Sorry, there was a problem downloading this file. We are looking into the issue. Please try again later."
          flashMessageDispatch(setErrorMessageAction({ message }))
          const errorAttrs = { code: error.code, message: error.message }
          Sentry.captureMessage(
            `Could not create download URL: ${JSON.stringify(errorAttrs)}`
          )
        })
    })
  )
}

const PrivateAssetDownloadIframes = ({ downloadUrls }: { downloadUrls: string[] }) => (
  <>
    {downloadUrls.map((downloadUrl, i) => (
      <iframe
        title={`download-iframe-${i}`}
        key={`download-iframe-${i}`}
        data-testid={`download-iframe-${i}`}
        frameBorder="0"
        style={{ width: 0, height: 0, visibility: 'hidden' }}
        src={downloadUrl}
      />
    ))}
  </>
)

type Props = {
  children: React.ReactNode
}

const PrivateAssetDownloadProvider = ({ children }: Props) => {
  const [downloadUrls, setDownloadUrls] = React.useState<string[]>([])
  const { flashMessageDispatch } = React.useContext(FlashMessageContext)

  const value = {
    downloadPrivateAsset: buildDownloadPrivateAsset({
      downloadUrls, setDownloadUrls, flashMessageDispatch
    })
  }

  return (
    <PrivateAssetDownloadContext.Provider value={value}>
      {children}
      <PrivateAssetDownloadIframes downloadUrls={downloadUrls} />
    </PrivateAssetDownloadContext.Provider>
  )
}

export default PrivateAssetDownloadProvider
