import React from 'react';
import { Event, EventOpts, MixpanelProperties } from 'Tracker'
import { BattleMap } from 'models'
import Rails from 'Rails'
import { openUrlInNewWindow } from 'window'
import { AppLoaderContext } from 'AppLoader'

type OpenExternalBattleMapFn = (battleMap: Pick<BattleMap, 'id'>, mixpanelProperties?: MixpanelProperties) => void

type SuggestedMapsContextValue = {
  openExternalBattleMap: OpenExternalBattleMapFn
}

export const SuggestedMapsContext = React.createContext<SuggestedMapsContextValue>({
  openExternalBattleMap: (battleMap: Pick<BattleMap, 'id'>, mixpanelProperties?: MixpanelProperties) => {}
})

const openExternalBattleMapInNewWindow = (battleMap: Pick<BattleMap, 'id'>, mixpanelProperties: MixpanelProperties = {}) => {
  const opts: EventOpts = {
    mixpanelProperties: {
      ...{ map_id: battleMap.id },
      ...(mixpanelProperties || {}),
    }
  }
  window.tracker.track(Event.ExternalMapPageOpened, opts);
  openUrlInNewWindow(Rails.apiUrl("/c/" + battleMap.id).toString())
}

type Props = {
  children: React.ReactNode
}

const SuggestedMapsProvider = ({ children }: Props) => {
  const { session } = React.useContext(AppLoaderContext)

  let openExternalBattleMap: OpenExternalBattleMapFn
  if (session) {
    openExternalBattleMap = openExternalBattleMapInNewWindow
  } else {
    openExternalBattleMap = (battleMap: Pick<BattleMap, 'id'>, mixpanelProperties?: MixpanelProperties) => {}
  }

  const value = { openExternalBattleMap }

  return (
    <SuggestedMapsContext.Provider value={value}>
      {children}
    </SuggestedMapsContext.Provider>
  )
}

export default SuggestedMapsProvider
