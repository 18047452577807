import React from 'react';
import { Button } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';

import { BattleMap } from 'models'
import { SuggestedMapsContext } from 'providers/SuggestedMapsProvider'

type Props = {
  map: Pick<BattleMap, 'id'>
  size?: 'small' | 'medium' | 'large'
}

const OpenExternalMapPageButton = ({ map, size }: Props) => {
  const { openExternalBattleMap } = React.useContext(SuggestedMapsContext)

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<OpenInNew />}
      onClick={() => openExternalBattleMap(map)}
      size={size || 'medium'}
      data-testid="open-in-new"
    >
      Open&nbsp;page&nbsp;(external)
    </Button>
  )
}

export default OpenExternalMapPageButton
