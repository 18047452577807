import { PageMetadata } from 'document'
import { s3BackedImageUrl } from 'images'

export type Creator = {
  id: number;
  slug: string;
  name: string;
  heroImageExtension: string;
  labelHeroImage: boolean;
  externalSite?: string;
  hostedMapsCount: number;
  premiumMapsCount: number;
  battleMapsCount: number;
  followersCount: number;
  allowFullZoom: boolean;
}

export const pageMetadataForCreator = (creator: Creator): PageMetadata => ({
  title: `${creator.name} battle maps - Lost Atlas`,
  metaDescription: `Search ${creator.name} battle maps on Lost Atlas. Lost Atlas is a battle map search engine and marketplace for D&D and other tabletop RPGs.`
})

export const isLostAtlasCreator = (c: Creator): boolean => (
  c.hostedMapsCount > 0 || c.premiumMapsCount > 0
)

export const backgroundImageUrl = (c: Creator): string => (
  s3BackedImageUrl('creators/heroes', `${c.slug}.${c.heroImageExtension}`)
)
