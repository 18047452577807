import React, { useState, useRef, useEffect } from 'react'
import { useSearchParams, useNavigate } from "react-router-dom";
import { Layout } from 'layouts'
import CenteredCircularProgress from 'components/CenteredCircularProgress';
import ApiClient from 'ApiClient'
import { Routes } from 'routing/deprecated'
import { Subscription } from 'models'
import * as Sentry from "@sentry/react"

const alertThreshold = 10

const SubscribeSuccessRedirect = () => {
  const [searchParams, _setSearchParams] = useSearchParams()
  const [subscription, setSubscription] = useState<Subscription>()
  const navigate = useNavigate()
  const fetchCount = useRef<number>(0)

  useEffect(() => {
    const timer = setInterval(() => {
      ApiClient
        .getCamelcased("/users/subscription")
        .then(resp => {
          if (resp.data) setSubscription(resp.data)
        })
        .finally(() => {
          fetchCount.current = fetchCount.current + 1
          if (fetchCount.current === alertThreshold) {
            Sentry.captureMessage(`SubscribeSuccessRedirect fetched subscription ${alertThreshold} times`)
          }
        })
    }, 100)

    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
      if (subscription && subscription.status === 'active') {
        let path = Routes.subscribeSuccessPath()
        if (searchParams.get('m')) {
          path = `${path}?m=${searchParams.get('m')}`
        }
        navigate(path)
      }
  }, [subscription])

  return (
    <Layout backgroundColor="light" padding="medium">
      <CenteredCircularProgress />
    </Layout>
  )
}

export default SubscribeSuccessRedirect;
