import { BattleMap } from './BattleMap'
import { MapVariation } from './MapVariation'
import { Product } from './Product'
import ApiClient from 'ApiClient'
import { CreateDownloadUrlResponse } from 'providers/PrivateAssetDownloadProvider'
import { AccessType } from './AccessType'

// BattleMap as it appears in Search or Explore, but not as it appears in
// shopping carts or orders
export interface BrowseableBattleMap extends BattleMap {
  accessType: AccessType
  product?: Product;
  variations: MapVariation[]
}

export type NewBrowseableBattleMap = Omit<BrowseableBattleMap, 'id'>

export const createHostedUrl = (bm: BrowseableBattleMap): Promise<CreateDownloadUrlResponse> => {
  if (!isDirectDownloadAccessType(bm)) throw new Error("Wrong access type")

  return new Promise((resolve, reject) => {
    ApiClient
      .postCamelcased("/d", { map_id: bm.id })
      .then(resp => resolve(resp.data))
      .catch(reject)
  })
}

export const isDirectDownloadAccessType = (bm: BrowseableBattleMap): boolean => (
  bm.accessType === AccessType.Hosted
)

export const isFreeExternalAccessType = (bm: BrowseableBattleMap): boolean => (
  bm.accessType === AccessType.External
)

export const isPremiumAccessType = (bm: BrowseableBattleMap): boolean => (
  bm.accessType === AccessType.Premium
)
