import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import { BattleMap } from 'models'
import { Header as PageHeader } from 'components/page'
import { Routes } from 'routing/deprecated'
import VttDownloadTargetSelector from 'components/VttDownloadTargetSelector'

type Props = {
  battleMap: Pick<BattleMap, 'name' | 'creator'>
}

const Header = ({ battleMap }: Props) => {
  return (
    <Box sx={{ display: 'flex', marginBottom: '20px' }}>
      <Box sx={{ flexGrow: 1 }}>
        <PageHeader>
          Download &ldquo;{battleMap.name}&rdquo;
        </PageHeader>
        <Box sx={{ position: 'relative' }}>
          <Box>
            By&nbsp;
            <Link to={Routes.creatorPagePath(battleMap.creator)}>
              {battleMap.creator.name}
            </Link>
          </Box>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, textAlign: 'right', position: 'relative' }}>
        <VttDownloadTargetSelector
          sx={{ position: 'absolute', bottom: 0, right: 0 }}
        />
      </Box>
    </Box>
  )
}

export default Header
