import React from 'react';
import { Box } from '@mui/material'
import { BrowseableBattleMap, Product } from 'models'
import { isDirectDownloadAccessType, isPremiumAccessType, isFreeExternalAccessType } from 'models/BrowseableBattleMap'
import { TrackerContext } from 'Tracker'
import { BookmarkButton, HostedButton, OpenInNewButton, ProductButton } from 'components/MapCardBase'
import { AppLoaderContext, AppLoaderContextValue } from 'AppLoader';

type Props = {
  battleMap: BrowseableBattleMap,
}

const Actions = ({ battleMap }: Props) => {
  const { session } = React.useContext(AppLoaderContext) as AppLoaderContextValue;

  if (!session) return null

  return (
    <TrackerContext.Provider
      value={{ mixpanelProperties: { source_component: 'Map card' } }}
    >
      <Box sx={{ paddingTop: '10px', paddingRight: '10px' }}>
        {isDirectDownloadAccessType(battleMap) && <HostedButton battleMap={battleMap} />}
        {isPremiumAccessType(battleMap) && (
          <ProductButton
            battleMap={battleMap}
            product={battleMap.product as Product}
            session={session}
          />
        )}
        {isFreeExternalAccessType(battleMap) && <OpenInNewButton battleMap={battleMap} />}
        <BookmarkButton battleMap={battleMap} />
      </Box>
    </TrackerContext.Provider>
  )
}

export default Actions;
